@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --toastify-color-error: linear-gradient(99.38deg, rgb(246, 190, 14) -6.68%, rgba(255, 188, 17, 0) 79.97%), rgb(201, 48, 46);
}

.slide-enter {
  transition: all 300ms ease-out;
}

.slide-enter {
  transform: translateY(20%);
  opacity: 0;
}

.slide-enter.slide-enter-active {
  transform: translateY(0%);
  opacity: 1;
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(0%);
  opacity: 1;
}

.slide-exit-active {
  transform: translateY(-20%);
  opacity: 0;
}
